<script setup>
  const { statusMarkdown } = storeToRefs(useContentStore());
  const { showScreenSizeOverlay } = storeToRefs(useBaseStore());

  const { data, error } = await useFetch(`/api/data`, {
    method: 'GET',
    query: {
      id: 'small-screen-warning',
      collection: 'markdowns',
    },
    transform(_data) {
      statusMarkdown.value = _data.data[0]?.attributes.content;
      return _data.data[0]?.attributes.content;
    },
  });

  const classes = ['text-red-300', 'align-middle'];
</script>

<template>
  <v-overlay
    v-model="showScreenSizeOverlay"
    class="relative flex justify-center items-center backdrop-blur-lg bg-slate-600 bg-opacity-60"
  >
    <div class="">
      <div>
        <v-img
          src="https://purchasing-strapi.s3.ap-southeast-2.amazonaws.com/stokkd_purchasing_white_76949ef505.svg"
          width="60%"
          contain
          alt="stokkd logo"
          class="mx-auto"
        />
      </div>
      <ElementMarkdown
        v-if="statusMarkdown"
        :content="statusMarkdown"
        class="text-white p-4"
      />
    </div>
  </v-overlay>
</template>
